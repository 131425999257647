import React from "react"
import phone from "./phone.png"

import AnchorLink from "react-anchor-link-smooth-scroll"
import "./navbar.css"

class Header extends React.Component {
  state = {
    isActive: false,
    isTop: true,
  }

  componentDidMount() {
    document.addEventListener("scroll", () => {
      const isTop = window.scrollY < 100
      if (isTop !== this.state.isTop) {
        this.onScroll(isTop)
      }
    })
  }

  onScroll = isTop => {
    this.setState({ isTop })
  }

  toggleNav = () => {
    this.setState(prevState => ({
      isActive: !prevState.isActive,
    }))
  }

  classRender() {
    if (!this.state.isTop) {
      return "navbar is-spaced is-fixed-top not-top"
    } else return "navbar is-spaced is-fixed-top top"
  }

  numberRender() {
    if (!this.state.isTop) {
      return "navbar-end "
    } else return "navbar-end number"
  }

  render() {
    return (
      <nav
        aria-label="main navigation"
        className={this.classRender()}
        role="navigation"
      >
        <div className="container">
          <div className="navbar-brand">
            <div className="navbar-item">
              <h5 className="title color-title logo">
                <AnchorLink className="logo" href="#home">
                  PROFILMEX
                </AnchorLink>
              </h5>
            </div>
            <button className="button navbar-burger" onClick={this.toggleNav}>
              <span />
              <span />
              <span />
            </button>
          </div>
          <div
            className={
              this.state.isActive ? "navbar-menu is-active" : "navbar-menu"
            }
          >
            <div className="navbar-start">
              <AnchorLink
                className="navbar-item"
                href="#home"
                onClick={this.toggleNav}
              >
                Home
              </AnchorLink>
              <AnchorLink
                className="navbar-item"
                href="#offer"
                onClick={this.toggleNav}
              >
                Oferta
              </AnchorLink>
              <AnchorLink
                className="navbar-item"
                href="#footer"
                onClick={this.toggleNav}
              >
                Kontakt
              </AnchorLink>
            </div>
            <div className={this.numberRender()}>
              <p className="navbar-item ">
                <img
                  className="telephone is-24x24"
                  src={phone}
                  alt="telephone"
                />
                <span className="phone">
                  {" "}
                  <a className="telephone link" href="tel: +48 503 654 655">
                    {" "}
                    +48 503 654 655
                  </a>
                </span>
              </p>
            </div>
          </div>
        </div>
      </nav>
    )
  }
}

export default Header
