import React from "react"
import { Helmet } from "react-helmet"
import "../components/main/main"
import "bulma/css/bulma.css"
import "./page.css"
import Navbar from "../components/navbar/Navbar"
import Main from "../components/main/main"
import Offer from "../components/offer/offer"
import Footer from "../components/footer/footer"

const IndexPage = () => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Profilmex</title>
    </Helmet>
    <Navbar />
    <Main />
    <Offer />
    <Footer />
  </>
)

export default IndexPage
