import React from "react"

import "./main.css"
const Main = () => {
  return (
    <div id="home">
      <section className="hero is-fullheight">
        <div className="container centered-content">
          <div className="columns is-vcentered ">
            <div className="column is-6 is5-fullhd ">
              <div className="section-header">
                <h1 className="title color-title logo is-spaced">PROFILMEX</h1>
                <h2 className="subtitle font is-3">
                  producent kształtowników zimnogiętych otwartych
                </h2>
                <div className="is-5">
                  Firma PROFILMEX zajmuje się produkcją kształtowników
                  zimnogiętych otwartych z blach stalowych ocynkowanych,
                  powlekanych oraz czarnych służących do realizacji hal
                  przemysłowych, obiektów handlowych, biurowych i
                  administracyjnych, konstrukcji stalowych pod panele
                  fotowoltaiczne, salonów samochodowych, kontenerów, a także
                  obiektów sportowych i rolniczych.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Main
