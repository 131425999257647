import React, { Component } from "react"
import Map from "../map/map"
class Footer extends Component {
  render() {
    return (
      <div id="footer">
        <footer className="footer">
          <div className="container">
            <div className="columns">
              <div className="column">
                <h2 className="title color-title font is-5">KONTAKT</h2>
                <br></br>
                <p className="is-5">PROFILMEX</p>
                <p className="is-5">Krzysztof Gronowski</p>
                <p className="is-5">Chrusty 58A, 98-170 Widawa</p>
                <br></br>
                <span className="is-5">
                  <a href="tel:+48 503 654 655" className="link">
                    tel. +48 503 654 655
                  </a>
                </span>
                <p className="is-5">
                  <a href="mailto:profilmex@o2.pl" className="link">
                    e-mail: profilmex@o2.pl
                  </a>
                </p>
                <br></br>
                <p className="is-5">NIP: 831-127-09-35</p>
              </div>
              <div className="column">
                <h2 className="title color-title font is-5">
                  GODZINY OTWARCIA
                </h2>
                <br></br>
                <p className="is-5">Poniedziałek - Piątek: 7:00 - 16:00</p>
              </div>
              <div className="column">
                <h2 className="title color-title font is-5">JAK DOJECHAĆ</h2>
                <br></br>
                <Map></Map>
              </div>
            </div>
          </div>
        </footer>
      </div>
    )
  }
}

export default Footer
