import React, { Component } from "react";

class Map extends Component {
  state = {};
  render() {
    return (
      <iframe
        title="map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2490.9991275531956!2d18.945481115718668!3d51.36631272883014!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471a427a0233cefb%3A0x1893d880a02475a9!2sProfilmex.%20Gronowski%20K.!5e0!3m2!1spl!2spl!4v1568019795078!5m2!1spl!2spl"
        width="350px"
        height="300px"
        frameBorder="0"
        allowFullScreen=""
      ></iframe>
    );
  }
}

export default Map;
