import React, { Component } from "react"
import Solar from "./1-min.jpeg"
import Angle from "./2-min.jpeg"
import Obr from "./3-min.jpeg"
import Paint from "./4-min.jpeg"
import Car from "./5-min.jpeg"
import "./offer.css"

class Offer extends Component {
  render() {
    return (
      <div id="offer">
        <div className=" section container">
          <div className="columns is-vcentered ">
            <div className="column is-half">
              <h2 className="title color-title font is-3">
                Kształtowniki zimnogięte otwarte
              </h2>
              <br></br>
              <p className="description">
                Specjalizujemy się w produkcji wysokiej jakości kształtowników
                zimnogiętych otwartych typu<br></br> Z, C, C+, U, L, Sigma,
                Omega wykonywanych ze stali ocynkowanej takiej jak DX51D+Z275,
                S350GD, S390GD oraz ze stali czarnej S235JR, S355JR, stali
                nierdzewnej, aluminium lub według potrzeb zamawiającego.
                Kształtowniki są produkowane na maszynych sterowanych
                numerycznie o grubości ścianek od 0,5mm do 4,0mm z dokładnością
                do 0,1mm.
              </p>
            </div>

            <div className="column img">
              <img src={Obr} alt="" className="image" />
            </div>
          </div>
          <div className="columns is-vcentered is-reverse">
            <div className="column is-half is-right ">
              <h2 className="title color-title font is-3  ">
                Obróbki blacharskie
              </h2>
              <br></br>
              <p className="description">
                Produkowane obróbki blacharskie stanowią nieodzowny element
                systemów pokryć dachowych <br></br> i elewacyjnych. Tworzą
                barierę przed niszczącym działaniem czynników atmosferycznych
                zabezpieczając mury i stropy przed zawilgoceniem oraz
                uszkodzeniem. Wykonywane są z blach powlekanych w różnych
                kolorach według palety RAL. Powierzchnia blach zabezpieczona
                jest folią ochronną, która chroni obróbki przed porysowaniem się
                podczas produkcji lub <br></br> w transporcie.
                <br></br>
                <br></br>
                Typ produkowanych obróbek: obróbki przyścienne, rynny koszowe,
                pasy nadrynnowe, kalenice proste, kalenice stożkowe, pasy
                podrynnowe, wiatrownice, bariery śniegowe, listwy wykończeniowe,
                parapety i inne według zamówienia klienta o długości do 6000mm.
              </p>
            </div>
            <div className="column img">
              <img src={Angle} alt="" className="image" />
            </div>
          </div>
          <div className="columns is-vcentered">
            <div className="column is-half">
              <h2 className="title color-title font is-3">
                Konstrukcje pod panele fotowoltaiczne
              </h2>
              <br></br>
              <p className="description">
                Wykonujemy także ocynkowane stalowe konstrukcje pod panele
                fotowoltaiczne z powłoką Magnelis, która dzięki swym wyjątkowym
                właściwościom zapewnia długotrwałą ochronę powierzchni w całym
                szeregu zastosowań.
              </p>
            </div>

            <div className="column img">
              <img src={Solar} alt="" className="image" />
            </div>
          </div>
          <div className="columns is-vcentered is-reverse">
            <div className="column is-half is-right">
              <h2 className="title color-title font is-3">
                Malowanie proszkowe
              </h2>
              <br></br>
              <p className="description">
                Proponujemy również malowanie proszkowe elementów stalowych
                wykonanych z blach ocynkowanych lub czarnych. W szczególnych
                przypadkach jesteśmy w stanie zapewnić zabezpieczenie
                powierzchni w klasie korozyjności C4 a nawet C5 wystawiając
                deklarację zgodności.
              </p>
            </div>

            <div className="column img">
              <img src={Paint} alt="" className="image" />
            </div>
          </div>
          <div className="columns is-vcentered">
            <div className="column is-half">
              <h2 className="title color-title font is-3">Transport</h2>
              <br></br>
              <p className="description">
                Zapewniamy dowóz naszych wyrobów do Klienta. Staramy się jak
                najszybciej realizować zamówienia i dostarczać produkty do
                naszych odbiorców.
              </p>
            </div>

            <div className="column img">
              <img src={Car} alt="" className="image" />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Offer
